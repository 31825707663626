// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileBtn_container__MrLYN {\n    width: 100%;\n    padding: 14px 20px;\n    margin-bottom: 16px;\n\n    display: grid;\n    grid-template-columns: 24px auto;\n    grid-gap: 20px;\n    align-items: center;\n\n    border-radius: 10px;\n\n    font-size: 20px;\n    font-weight: 500;\n    line-height: normal;\n\n    cursor: pointer;\n}\n.ProfileBtn_container__MrLYN:hover {\n    background: #EAEAEA;\n}\n\n.ProfileBtn_hover__z744p {\n    background: #EAEAEA;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ProfileBtn/ProfileBtn.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,mBAAmB;;IAEnB,aAAa;IACb,gCAAgC;IAChC,cAAc;IACd,mBAAmB;;IAEnB,mBAAmB;;IAEnB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;;IAEnB,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".container {\n    width: 100%;\n    padding: 14px 20px;\n    margin-bottom: 16px;\n\n    display: grid;\n    grid-template-columns: 24px auto;\n    grid-gap: 20px;\n    align-items: center;\n\n    border-radius: 10px;\n\n    font-size: 20px;\n    font-weight: 500;\n    line-height: normal;\n\n    cursor: pointer;\n}\n.container:hover {\n    background: #EAEAEA;\n}\n\n.hover {\n    background: #EAEAEA;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ProfileBtn_container__MrLYN",
	"hover": "ProfileBtn_hover__z744p"
};
export default ___CSS_LOADER_EXPORT___;
