// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhoneMenu_container__oXfD3 {\n    display: none;\n}\n\n.PhoneMenu_burger__aYpa8 {\n    display: none;\n    width: 50px;\n    height: 50px;\n    background-color: rgb(207, 207, 207);\n    border-radius: 40%;\n    cursor: pointer;\n}\n\n.PhoneMenu_login__x6Saw {\n    background: rgba(0, 0, 0, 0);\n    border: none;\n\n    color: white;\n    font-size: 16px;\n    font-weight: 500;\n\n    cursor: pointer;\n}\n\n.PhoneMenu_avatar__36o5U {\n    width: 30px;\n    height: 30px;\n}\n\n@media only screen and (max-width: 768px) {\n    .PhoneMenu_burger__aYpa8 {\n        display: block;\n    }\n\n    .PhoneMenu_container__oXfD3 {\n        margin-left: auto;\n        display: grid;\n        grid-template-columns: auto auto;\n        grid-gap: 30px;\n        align-items: center;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/PhoneMenu.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,4BAA4B;IAC5B,YAAY;;IAEZ,YAAY;IACZ,eAAe;IACf,gBAAgB;;IAEhB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,iBAAiB;QACjB,aAAa;QACb,gCAAgC;QAChC,cAAc;QACd,mBAAmB;IACvB;AACJ","sourcesContent":[".container {\n    display: none;\n}\n\n.burger {\n    display: none;\n    width: 50px;\n    height: 50px;\n    background-color: rgb(207, 207, 207);\n    border-radius: 40%;\n    cursor: pointer;\n}\n\n.login {\n    background: rgba(0, 0, 0, 0);\n    border: none;\n\n    color: white;\n    font-size: 16px;\n    font-weight: 500;\n\n    cursor: pointer;\n}\n\n.avatar {\n    width: 30px;\n    height: 30px;\n}\n\n@media only screen and (max-width: 768px) {\n    .burger {\n        display: block;\n    }\n\n    .container {\n        margin-left: auto;\n        display: grid;\n        grid-template-columns: auto auto;\n        grid-gap: 30px;\n        align-items: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PhoneMenu_container__oXfD3",
	"burger": "PhoneMenu_burger__aYpa8",
	"login": "PhoneMenu_login__x6Saw",
	"avatar": "PhoneMenu_avatar__36o5U"
};
export default ___CSS_LOADER_EXPORT___;
