import React from 'react'
import classes from './ProfilePaidArticles.module.css'
import Development from '../Develepment/Development'

function ProfilePaidArticles() {
    return (
        <Development />
    )
}

export default ProfilePaidArticles