// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contact_container__ZHcRM {\n    display: grid;\n    grid-template-columns: 40px 160px;\n    grid-gap: 10px;\n    justify-content: end;\n    align-items: center;\n\n    font-size: 20px;\n    font-weight: 400;\n    color: white;\n}\n.Contact_container__ZHcRM:hover {\n    cursor: pointer;\n    text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Footer/Contact.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iCAAiC;IACjC,cAAc;IACd,oBAAoB;IACpB,mBAAmB;;IAEnB,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".container {\n    display: grid;\n    grid-template-columns: 40px 160px;\n    grid-gap: 10px;\n    justify-content: end;\n    align-items: center;\n\n    font-size: 20px;\n    font-weight: 400;\n    color: white;\n}\n.container:hover {\n    cursor: pointer;\n    text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Contact_container__ZHcRM"
};
export default ___CSS_LOADER_EXPORT___;
