// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Development_container__jukzQ {\n    display: grid;\n    grid-template-rows: repeat(3, 1fr);\n    grid-gap: 25px;\n    text-align: center;\n    margin-bottom: auto;\n    padding: 0 5%;\n}\n\n.Development_container__jukzQ h1 {\n    color: #035629;\n}\n\n.Development_container__jukzQ p {\n    opacity: 0.5;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Develepment/Development.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kCAAkC;IAClC,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".container {\n    display: grid;\n    grid-template-rows: repeat(3, 1fr);\n    grid-gap: 25px;\n    text-align: center;\n    margin-bottom: auto;\n    padding: 0 5%;\n}\n\n.container h1 {\n    color: #035629;\n}\n\n.container p {\n    opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Development_container__jukzQ"
};
export default ___CSS_LOADER_EXPORT___;
