// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GreenBtn_btn__D7gIf {\n    display: inline-flex;\n    padding: 14px 32px;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n\n    color: #035629;\n    font-size: 16px;\n    font-weight: 600;\n\n    background: white;\n    border: 2px solid #035629;\n    border-radius: 50px;\n\n    transition: 250ms;\n}\n.GreenBtn_btn__D7gIf:hover {\n    color: white;\n    background: #035629;\n    cursor: pointer;\n}\n\n@media only screen and (max-width: 768px) {\n    .GreenBtn_btn__D7gIf {\n        padding: 8px 16px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/GreenBtn/GreenBtn.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;;IAET,cAAc;IACd,eAAe;IACf,gBAAgB;;IAEhB,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;;IAEnB,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".btn {\n    display: inline-flex;\n    padding: 14px 32px;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n\n    color: #035629;\n    font-size: 16px;\n    font-weight: 600;\n\n    background: white;\n    border: 2px solid #035629;\n    border-radius: 50px;\n\n    transition: 250ms;\n}\n.btn:hover {\n    color: white;\n    background: #035629;\n    cursor: pointer;\n}\n\n@media only screen and (max-width: 768px) {\n    .btn {\n        padding: 8px 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "GreenBtn_btn__D7gIf"
};
export default ___CSS_LOADER_EXPORT___;
