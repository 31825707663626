// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconBtn_container__b0HTD {\n    padding: 16px 20px;\n\n    display: grid;\n    grid-template-columns: 30px auto;\n    grid-gap: 20px;\n    align-items: center;\n\n    border: 2px solid #B4B4B4;\n    border-radius: 50px;\n\n    color: #53565A;\n}\n.IconBtn_container__b0HTD:hover {\n    background-color: #035629;\n    border-color: #035629;\n    color: white;\n    cursor: pointer;\n}\n\n.IconBtn_container__b0HTD p {\n    align-self: center;\n\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 24px; /* 120% */\n}\n\n@media only screen and (max-width: 768px) {\n    .IconBtn_container__b0HTD {\n        padding: 10px 20px;\n    }\n\n    .IconBtn_container__b0HTD p {\n        font-size: 14px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/IconBtn/IconBtn.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,aAAa;IACb,gCAAgC;IAChC,cAAc;IACd,mBAAmB;;IAEnB,yBAAyB;IACzB,mBAAmB;;IAEnB,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;;IAElB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;AAChC;;AAEA;IACI;QACI,kBAAkB;IACtB;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container {\n    padding: 16px 20px;\n\n    display: grid;\n    grid-template-columns: 30px auto;\n    grid-gap: 20px;\n    align-items: center;\n\n    border: 2px solid #B4B4B4;\n    border-radius: 50px;\n\n    color: #53565A;\n}\n.container:hover {\n    background-color: #035629;\n    border-color: #035629;\n    color: white;\n    cursor: pointer;\n}\n\n.container p {\n    align-self: center;\n\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 24px; /* 120% */\n}\n\n@media only screen and (max-width: 768px) {\n    .container {\n        padding: 10px 20px;\n    }\n\n    .container p {\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "IconBtn_container__b0HTD"
};
export default ___CSS_LOADER_EXPORT___;
