import React from 'react'
import classes from './ProfileTrips.module.css'
import Development from '../Develepment/Development'

function ProfileTrips() {
    return (
        <Development />
    )
}

export default ProfileTrips