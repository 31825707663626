// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PhoneProfileMenu_container__4TWaI {\n    width: 100%;\n    height: 100%;\n\n    z-index: 10;\n    position: fixed;\n    top: -100%;\n\n    padding: 20px 10% 20px 10%;\n\n    background-color: white;\n\n    transition: 0.5s ease;\n\n    overflow-x: hidden;\n    overflow-y: scroll;\n}\n\n@media only screen and (max-width: 768px) {\n    .PhoneProfileMenu_container__4TWaI.PhoneProfileMenu_active__b9Utr {\n        top: 10vh;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/PhoneProfileMenu.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,WAAW;IACX,eAAe;IACf,UAAU;;IAEV,0BAA0B;;IAE1B,uBAAuB;;IAEvB,qBAAqB;;IAErB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI;QACI,SAAS;IACb;AACJ","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n\n    z-index: 10;\n    position: fixed;\n    top: -100%;\n\n    padding: 20px 10% 20px 10%;\n\n    background-color: white;\n\n    transition: 0.5s ease;\n\n    overflow-x: hidden;\n    overflow-y: scroll;\n}\n\n@media only screen and (max-width: 768px) {\n    .container.active {\n        top: 10vh;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PhoneProfileMenu_container__4TWaI",
	"active": "PhoneProfileMenu_active__b9Utr"
};
export default ___CSS_LOADER_EXPORT___;
