import React from 'react'
import classes from './Development.module.css'

function Development() {
    return (
        <div className={classes.container}>
            <h1>Ёлки-иголки...</h1>
            <h3>Данная страница находится в разработке</h3>
            <p>если честно, не думали, что вы так далеко зайдёте...</p>
        </div>
    )
}

export default Development