// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TourDetail_container__5bmmR {\n    width: 100%;\n}\n\n.TourDetail_container__5bmmR h1 {\n    text-align: center;\n    margin: 5% 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/TourDetail/TourDetail.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".container {\n    width: 100%;\n}\n\n.container h1 {\n    text-align: center;\n    margin: 5% 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TourDetail_container__5bmmR"
};
export default ___CSS_LOADER_EXPORT___;
