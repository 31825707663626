// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Profile_container__PEHJT {\n    min-height: calc(90vh - 50px);\n    margin: 0 10% 50px 10%;\n\n    display: grid;\n    grid-template-columns: 25% auto;\n    grid-gap: 10%;\n}\n\n@media only screen and (max-width: 1200px) {\n    .Profile_container__PEHJT {\n        margin: 0 5% 50px 5%;\n    }\n}\n\n@media only screen and (max-width: 992px) {\n    .Profile_container__PEHJT {\n        margin: 0 5% 30px 5%;\n    }\n}\n\n@media only screen and (max-width: 768px) {\n    .Profile_container__PEHJT {\n        margin: 0 5% 30px 5%;\n        min-height: calc(90vh - 30px);\n        grid-template-columns: auto;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Profile/Profile.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,sBAAsB;;IAEtB,aAAa;IACb,+BAA+B;IAC/B,aAAa;AACjB;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,oBAAoB;QACpB,6BAA6B;QAC7B,2BAA2B;IAC/B;AACJ","sourcesContent":[".container {\n    min-height: calc(90vh - 50px);\n    margin: 0 10% 50px 10%;\n\n    display: grid;\n    grid-template-columns: 25% auto;\n    grid-gap: 10%;\n}\n\n@media only screen and (max-width: 1200px) {\n    .container {\n        margin: 0 5% 50px 5%;\n    }\n}\n\n@media only screen and (max-width: 992px) {\n    .container {\n        margin: 0 5% 30px 5%;\n    }\n}\n\n@media only screen and (max-width: 768px) {\n    .container {\n        margin: 0 5% 30px 5%;\n        min-height: calc(90vh - 30px);\n        grid-template-columns: auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Profile_container__PEHJT"
};
export default ___CSS_LOADER_EXPORT___;
