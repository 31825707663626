// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfileFavorites_empty__QqXOD {\n    margin-top: 50px;\n    margin-bottom: auto;\n\n    display: grid;\n    grid-template-rows: repeat(3, 1fr);\n    justify-content: center;\n    align-items: center;\n    grid-gap: 50px;\n    \n    text-align: center;\n}\n\n.ProfileFavorites_empty__QqXOD h1 {\n    font-size: 40px;\n    font-weight: 600;\n}\n\n.ProfileFavorites_empty__QqXOD p {\n    font-size: 24px;\n    font-weight: 400;\n    letter-spacing: -0.24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ProfileFavorites/ProfileFavorites.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;;IAEnB,aAAa;IACb,kCAAkC;IAClC,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;;IAEd,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".empty {\n    margin-top: 50px;\n    margin-bottom: auto;\n\n    display: grid;\n    grid-template-rows: repeat(3, 1fr);\n    justify-content: center;\n    align-items: center;\n    grid-gap: 50px;\n    \n    text-align: center;\n}\n\n.empty h1 {\n    font-size: 40px;\n    font-weight: 600;\n}\n\n.empty p {\n    font-size: 24px;\n    font-weight: 400;\n    letter-spacing: -0.24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty": "ProfileFavorites_empty__QqXOD"
};
export default ___CSS_LOADER_EXPORT___;
